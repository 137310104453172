import useGlobalLoader from '@/hooks/use-global-loader'
import { Backdrop } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const GlobalLoader = () => {
  const { isLoading } = useGlobalLoader()
  return (
    <Backdrop className='z-[99999999999] text-grayscale-000' open={isLoading}>
      <CircularProgress color='primary' />
    </Backdrop>
  )
}

export default GlobalLoader
