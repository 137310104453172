import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { isWuAppWebview } from '@libs-components/utils/device'
import { shopPaths } from '@/libs/routes'

const DesktopNavBar = dynamic(
  () => import('@/components/layouts/common/components/desktop-nav-bar'),
  { ssr: false, loading: () => <div className='h-[60px] mobileUp:h-[90px]' /> },
)
const AppNavBar = dynamic(() => import('@/components/layouts/common/components/app-nav-bar'), {
  ssr: false,
  loading: () => <div className='h-[44px]' />,
})

const NavBar = () => {
  const route = useRouter()
  const shouldHideNavbar = route.pathname.includes(shopPaths.OUTCOME)

  if (shouldHideNavbar) return <div />

  if (isWuAppWebview()) {
    return <AppNavBar />
  }

  return <DesktopNavBar />
}

export default NavBar
