import { LOCALE_TW } from '@/../next-i18next.config'
import { isAppWebview } from '@libs-components/utils/device'
import { getQueryString } from '@libs-components/utils/url'

export interface ExamPagePropsTypes {
  materialId: string
  fullTestId: string
  preferredAppPlatform?: 'ios' | 'android'
}

export const withFullShopsUrl = (path: string, langInRoute: string = LOCALE_TW) =>
  `${process.env.NEXT_PUBLIC_SELF_DOMAIN}/${langInRoute}${path || ''}`

export const Web2Url = (path: string) =>
  `${process.env.NEXT_PUBLIC_FLASHCARD_WEB_DOMAIN}${path || ''}`

export const Web2Paths = {
  DECKS: '',
  MATERIALS: '',
  SIGN_IN: '/auth/sign-in',
  SIGN_UP: '/auth/sign-up',
  FORGET_PASSWORD: '/auth/forget-password',
  RESET_PASSWORD: '/auth/reset-password',
  SETTING_ACCOUNT: '/account/settings',
  SETTING_PREFERENCE: '/account/preference',
  SETTING_SUBSCRIPTION: '/account/subscription-order-history',
  ADMIN_INDEX: '/dashboard/classes',
}

export const shopPaths = {
  DETAIL: ({
    productId,
    tab,
    queries = [],
  }: {
    productId: string
    tab?: 'intro' | 'toc' | 'reviews' | 'comments' | 'outcomes' | 'faq'
    queries?: (string | undefined | null)[]
  }) => {
    queries = queries.filter(q => q)
    const querystring = (tab ? [`tab=${tab}`, ...queries] : queries).join('&')
    return `/product/${productId}${querystring ? `?${querystring}` : ''}`
  },
  COLLECTION_OFFERS: ({ productId }: { productId: string }) => `/collection-offers/${productId}`,
  CART: '/cart',
  BUYING_GROUP_TEMPLATE: '/bg',
  SEARCH_TEMPLATE: '/search',
  RECOMMENDATIONS_RESULT: () => '/search/recommendations/result',
  RECOMMENDATIONS: () => '/search/recommendations',
  TERMS_OF_SERVICE: '/terms-of-service',
  FB_REDIRECT: `${process.env.NEXT_PUBLIC_SELF_DOMAIN}/fb-auth`,
  EVENT_GENERAL: `/sanmin-109-tecc-general`,
  EVENT_STUDENT: `/sanmin-109-tecc`,
  EVENT_TOEIC_MINI_TEST_LIVEABC: `/toeic-mini-test-liveabc`,
  SAMIN_SUBSCRIPTION_PLAN: '/samin-subscription-plan',
  SANMIN_GSAT_MOCK_TEST: '/sanmin-gsat-mock-test',
  CHRISTMAS_CAMPAIGN: '/christmas-campaign',
  REVIEW_2020: '/review-2020',
  REVIEW_2020_USER: '/review-2020/user',
  RATING: '/rating/edit',
  RATING_SUCCESS: '/rating/success',
  CAMPAIGN_HOT_SALE: '/campaign/hot-sale',
  CAMPAIGN_BIZ_ENG: '/campaign/business_english',
  CAMPAIGN_TOEIC_12: '/campaign/toeic_12',
  CAMPAIGN_TIBE2022_WORK: '/campaign/tibe2022_work',
  CAMPAIGN_TIBE2022_ELEMENTARY: '/campaign/tibe2022_elementary',
  CAMPAIGN_TIBE2022_JUNIOR_HIGH: '/campaign/tibe2022_junior_high',
  CAMPAIGN_2021_BTS: '/2021bts',
  CAMPAIGN_JELLA: '/campaign/jella',
  CAMPAIGN_JELLA_GUIDED_LEARNING_PROGRAM: '/campaign/jella_guided_learning_program',
  TOPIC: '/topic',
  TEACHING: '/teaching',
  THANK_YOU: '/thank-you',
  OUTCOME: '/outcome',
  TRIAL_QUESTION_GROUP: '/trial/question-group',
  ABOUT_US: '/about',
  HOME: () => {
    if (isAppWebview()) {
      return '/app-home'
    }

    return '/'
  },
  SEARCH: (query: Partial<{ subject: string }> = {}) => {
    const querystring = getQueryString({ obj: query })
    return querystring?.length ? `/search?${querystring}` : '/search'
  },
}

export function paramsToObject(entries: [string, string][]) {
  const result: {
    [key: string]: string
  } = {}
  for (const [key, value] of entries) {
    result[key] = value
  }
  return result
}

export const getExamPageUrl = ({
  materialId,
  fullTestId,
  preferredAppPlatform,
}: ExamPagePropsTypes) => {
  const preferredAppPlatformParam = preferredAppPlatform
    ? `&preferredAppPlatform=${preferredAppPlatform}`
    : ''

  return `${process.env.NEXT_PUBLIC_FLASHCARD_WEB_DOMAIN}/exam/${fullTestId}?materialId=${materialId}${preferredAppPlatformParam}`
}

export function webMaterialDetail({
  productId,
  appView = 'default',
}: {
  productId: string
  appView: string
}) {
  if (appView === 'default') {
    return `${process.env.NEXT_PUBLIC_FLASHCARD_WEB_DOMAIN}/materials/${productId}`
  } else {
    return `${process.env.NEXT_PUBLIC_FLASHCARD_OLD_WEB_DOMAIN}/app/materials/${productId}?appView=${appView}`
  }
}

export function detailPageRoute(materialId: string) {
  return `/product/${materialId}`
}

export function subscriptionDetailRoute(subscriptionId: string) {
  return `/subscriptions/${subscriptionId}`
}

export const iOSDownloadUrl =
  'https://apps.apple.com/tw/app/wordup-%E5%96%AE%E5%AD%97%E6%95%91%E6%98%9F/id1447838299'

export const androidDownloadUrl =
  'https://play.google.com/store/apps/details?id=com.wordup.androidapp'
