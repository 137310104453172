import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import { shopPaths } from '@/libs/routes'
import { Icon } from '@libs-components/components/icon'

export const SuccessfullyAddCartToastContent = () => {
  const { t } = useTranslation()

  return (
    <div className='flex items-center justify-between'>
      <div>{t('cart.noti.added')}</div>

      <Link href={shopPaths.CART} className='no-underline'>
        <div className='mr-3 flex items-center gap-2 rounded-lg bg-green-500 p-[4px_8px] text-sm text-grayscale-000'>
          {t('cart.noti.go')}
          <Icon type='keyboard_arrow_right' />
        </div>
      </Link>
    </div>
  )
}
