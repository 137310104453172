import styled from '@emotion/styled'
import React from 'react'

import themeVar from '../../theme/themeVar'
import { LangType } from '../auth-form/types'
import { LecturerLocalProvider, useLecturerLocale } from './local-provider'

export interface LecturerPropTypes {
  linkData: { name: string; url: string; imageUrl: string; description: string }
  lang: LangType
}

export const Lecturer = ({
  lang = 'zh-TW',
  ...restProps
}: LecturerPropTypes) => (
  <LecturerLocalProvider defaultLang={lang}>
    <LecturerCard {...restProps} />
  </LecturerLocalProvider>
)

export const LecturerCard = ({ linkData }: Omit<LecturerPropTypes, 'lang'>) => {
  const { url, name, description, imageUrl } = linkData
  const { Lecturer } = useLecturerLocale()

  return (
    <SingleItemContainer>
      <ImageContainer>
        <StyledImg src={imageUrl} alt={`${name}`} loading='lazy' decoding='async' />
      </ImageContainer>

      <BottomContainer>
        <SingleTitleContainerDiv>
          <SingleTitleContainer>{name}</SingleTitleContainer>
          <SingleDescriptionContainer>{description}</SingleDescriptionContainer>
        </SingleTitleContainerDiv>
        <ButtonArea>
          <CtaMoreBtn target="_blank" href={url}>
            {Lecturer.check_detail}
          </CtaMoreBtn>
        </ButtonArea>
      </BottomContainer>
    </SingleItemContainer>
  )
}

LecturerCard.defaultProps = {
  linkData: {
    name: '',
    url: '',
    imageUrl: '',
  },
}

const SingleItemContainer = styled.div`
  max-width: 241px;
  max-height: 277px;
  border-radius: ${themeVar.rounded.m};
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
  justify-content: flex-start;
`

const SingleTitleContainer = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  font-weight: bold;
  font-size: ${themeVar.fontSize.m};
  text-align: start;
  color: ${themeVar.color.palette['grayscale-800']};
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-weight: 500;
`

const SingleDescriptionContainer = styled(SingleTitleContainer)`
  font-size: ${themeVar.fontSize.sm};
  color: #969696;
  -webkit-line-clamp: 1;
`

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${() =>
    `${themeVar.distance.sm} ${themeVar.distance.m} ${themeVar.distance.m} ${themeVar.distance.m}`};
  height: 152px;
  max-height: 152px;
  box-sizing: border-box;
`

const SingleTitleContainerDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 8px;
`

const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const CtaMoreBtn = styled.a`
  width: 100%;
  padding: 0.5rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: ${themeVar.color.palette['primary-500']};
  color: ${themeVar.color.palette['grayscale-000']};
  border-radius: ${themeVar.rounded.full};
  font-size: ${themeVar.fontSize.sm};
  font-weight: 500;
`

const ImageContainer = styled.div`
  position: relative;
  outline: none;
  flex: none;
  overflow-y: hidden;
  max-height: 7.875rem;
  min-height: 7.875rem;
  img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`

const StyledImg = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: 365/191;
  object-fit: cover;
`
