import React, { ReactNode } from 'react'
import { toast as _toast, ToastContent, ToastOptions } from 'react-toastify'
import TAILWIND_CONFIG from 'tailwind.config'

import { Icon } from '@libs-components/components/icon'

import { SuccessfullyAddCartToastContent } from './toast-contents'

import 'react-toastify/dist/ReactToastify.css'

type ToastOptionsType = ToastOptions<{}> | undefined

export const WuToastTypes = ['success', 'error', 'info', 'warning'] as const

export interface WuToastProps {
  message: ToastContent
  options?: ToastOptionsType
  actionComponent?: React.ReactNode
}

const TailwindColor = TAILWIND_CONFIG.theme.extend.colors

const toast = {
  ..._toast,
  success: ({ message, options, actionComponent }: WuToastProps) => {
    _toast.success(<MessageComp message={message} actionComponent={actionComponent} />, {
      ...options,
      icon: () => (
        <Icon type='check_circle' style={{ color: TailwindColor['green-500'] }} size={20} />
      ),
    })
  },
  error: ({ message, options, actionComponent }: WuToastProps) => {
    _toast.error(<MessageComp message={message} actionComponent={actionComponent} />, {
      ...options,
      icon: <Icon type='dangerous' style={{ color: TailwindColor['peach-500'] }} size={20} />,
    })
  },
  info: ({ message, options, actionComponent }: WuToastProps) => {
    _toast.info(<MessageComp message={message} actionComponent={actionComponent} />, {
      ...options,
      icon: <Icon type='info' style={{ color: TailwindColor['grayscale-300'] }} size={20} />,
    })
  },
  warning: ({ message, options, actionComponent }: WuToastProps) => {
    _toast.warning(<MessageComp message={message} actionComponent={actionComponent} />, {
      ...options,
      icon: <Icon type='emergency_home' style={{ color: TailwindColor['yellow-500'] }} size={20} />,
    })
  },
  addToCartSuccess: () => {
    const options: ToastOptionsType = window.matchMedia(
      `(min-width:${TAILWIND_CONFIG.theme.extend.screens.mobileUp})`,
    ).matches
      ? {}
      : { position: 'top-right' }
    _toast.success(<SuccessfullyAddCartToastContent />, {
      ...options,
      icon: <Icon type='shopping_cart' style={{ color: TailwindColor['green-500'] }} size={20} />,
    })
  },
}

export default toast

const MessageComp = ({
  message,
  actionComponent,
}: {
  message: ToastContent
  actionComponent?: React.ReactNode
}) => {
  return (
    <div className='flex items-center justify-between text-xs font-medium leading-4 text-grayscale-800'>
      {message as ReactNode}
      {actionComponent}
    </div>
  )
}
