import { isEmpty } from 'lodash-es'
import CookieUtils from '@/libs/cookie'

const ALLOWED_UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_ad_set'] as const

const WU_UTM = 'WuUtm'

type COOKIE_UTM = Partial<{
  [key in (typeof ALLOWED_UTM_PARAMS)[number]]: string
}>

const parse = () => {
  const urlSearch = new URL(decodeURIComponent(encodeURIComponent(window.location)))
  const urlParams = new URLSearchParams(urlSearch.search)
  const parsedParams = {}

  ALLOWED_UTM_PARAMS.forEach(key => {
    const paramValue = urlParams.get(key)

    if (paramValue) {
      parsedParams[key] = paramValue
    }
  })

  return parsedParams
}

const saveToCookie = () => {
  const utms = parse()
  if (isEmpty(utms)) return

  const UTM_DURATION_MINS = 30
  const expireAt = new Date(new Date().getTime() + UTM_DURATION_MINS * 60 * 1000)
  CookieUtils.set({
    key: WU_UTM,
    value: JSON.stringify(utms),
    expires: expireAt,
    domain: process.env.NEXT_PUBLIC_WU_SUB_DOMAIN as string,
  })
}

const getFromCookie = () => {
  return CookieUtils.get(WU_UTM, true) as COOKIE_UTM
}

export type { COOKIE_UTM }
export default { saveToCookie, getFromCookie, parse }
