import axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { isEmpty } from 'lodash-es'
import { i18n } from 'next-i18next'
import { isWuAppWebview, getWuAppPlatformInfo } from '@libs-components/utils/device'

import { LOCALE_TW } from '@/../next-i18next.config'
import PackageInfo from '@/../package.json'
import Swal from '@/components/sweetalert'
import { utils as authUtils } from '@/features/auth'
import { isStaging } from '@/libs/env'
import { USER_NOT_LOGGED_IN } from '../error-messages'
import { isExpectedError } from './is-expected-error'
import sendUnexpectedErrorNotify from './send-unexpected-error-notify'

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_DOMAIN,
  headers: (() => {
    let defaultHeaders = {
      'Content-Type': 'application/json',
      'Client-Info': `shop/${PackageInfo.version}`,
      'Client-Lang': LOCALE_TW,
      'End-User-Client-Info': 'N/A',
    }

    if (i18n && i18n.resolvedLanguage) {
      defaultHeaders['Client-Lang'] = i18n.resolvedLanguage
    }

    if (isWuAppWebview()) {
      const { platform, version } = getWuAppPlatformInfo()
      defaultHeaders['End-User-Client-Info'] = `${platform}/${version}`
    }

    return defaultHeaders
  })(),
})

api.interceptors.response.use(
  response => {
    if (response.data) {
      response.data = camelcaseKeys(response.data, { deep: true })
    }
    return response
  },
  error => {
    const { isExpected, responseErrorDetail, responseErrorCode } = isExpectedError(error)
    if (isExpected) {
      const shouldThrowError = !error?.config?.ignoreThrowError || !error?.config?.isIgnoreError
      return shouldThrowError ? Promise.reject(error) : undefined
    }

    sendUnexpectedErrorNotify(error, { responseErrorDetail, responseErrorCode })

    return Promise.reject(error)
  },
)

type ApiCallerConfigType<T> = AxiosRequestConfig & {
  errorContext?: { [key: string]: any }
  isIgnoreError?: boolean
  isWithAuth?: boolean
  mockData?: { data: T; status?: number }
  // @deprecated
  locale?: string
  ignoreThrowError?: boolean
  requiresLogin?: boolean
}

const apiCaller = async <T>(config: ApiCallerConfigType<T>): Promise<AxiosResponse<T>> => {
  if (isStaging) {
    if (config.mockData) {
      console.warn(`
        --------------------------------------
        Please notice this warning. \n
        The mock data founded in the staging, the mock data returned.
        Create the mock data will be useful for frontend blocked by backend or testing.
        --------------------------------------
      `)
      const mockResp: AxiosResponse<T> = {
        ...config.mockData,
        status: config.mockData.status || 200,
        headers: config.headers || {},
        config: config as InternalAxiosRequestConfig<T>,
        statusText: 'Mock data will return from caller',
      }

      return mockResp.status < 400 ? Promise.resolve(mockResp) : Promise.reject(mockResp)
    }
  }

  const authCredentials = authUtils.getCredential()
  if ((config.requiresLogin || config.isWithAuth) && isEmpty(authCredentials)) {
    if (i18n) {
      Swal.fire({
        title: i18n.t('common.login_and_retry'),
        icon: 'info',
        confirmButtonText: i18n!.t('common.confirm'),
      })
    }
    return Promise.reject(new Error(USER_NOT_LOGGED_IN))
  }

  config.headers = { ...(config.headers || {}), ...authCredentials }

  return api.request(config)
}

export default apiCaller
