import snakecaseKeys from 'snakecase-keys'

import { BuyingGroupApiParamsType, BuyingGroupErrorCode } from './buying-group-types'

export const EXPECTED_ERROR_CODES: BuyingGroupErrorCode[] = [
  'buying_group_closed',
  'duplicated_join_group_buying',
]

export const getBuyingGroupInfo = (buyingGroupId: string) =>
  ({
    url: `/v1/shop/buying_groups/${buyingGroupId}`,
    method: 'GET',
    errorContext: {
      key: 'get_buying_group_info_error',
    },
  } as const)

export const joinBuyingGroup = ({
  buyingGroupId,
  params,
}: {
  buyingGroupId: string
  params: BuyingGroupApiParamsType
}) =>
  ({
    url: `/v1/shop/buying_groups/${buyingGroupId}/join`,
    method: 'POST',
    data: {
      data: snakecaseKeys(params),
    },
  } as const)

export const createBuyingGroup = ({ params }: { params: BuyingGroupApiParamsType }) => ({
  url: '/v1/shop/buying_groups',
  method: 'POST',
  data: {
    data: snakecaseKeys(params),
  },
})

export const getJoinedBuyingGroup = ({ productId }: { productId: string }) => ({
  url: '/v1/shop/buying_groups',
  method: 'GET',
  params: {
    product_id: productId,
  },
})

export const updateBuyingGroup = ({
  buyingGroupId,
  params,
}: {
  buyingGroupId: string
  params: Partial<BuyingGroupApiParamsType>
}) =>
  ({
    url: `/v1/shop/buying_groups/${buyingGroupId}`,
    method: 'PATCH',
    data: {
      data: params,
    },
  } as const)
