const plugin = require('tailwindcss/plugin')

module.exports = {
  // we use CssBaseline from @material-ui/core, so we don't need the preflight
  corePlugins: {
    preflight: false,
  },
  content: ['./src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: {
      listStyleType: {
        circle: 'circle',
      },
      zIndex: {
        navbar: 1000,
        announcement: 1010,
        backdrop: 1020,
        'bottom-sheet': 1030,
        modal: 1040,
        dropdown: 1050,
        tooltip: 1060,
        toast: 1070,
      },
      screens: {
        sm: '600px',
        mobileUp: '768px',
        md: '900px',
        lg: '1200px',
        xl: '1536px',
      },
      transitionProperty: {
        height: 'height',
        spacing: 'margin, padding',
        padding: 'padding',
        minWidth: 'min-width',
        maxWidth: 'max-width',
        maxHeight: 'max-height',
        left: 'left',
        right: 'right',
        top: 'top',
        bottom: 'bottom',
        width: 'width',
      },
      // from @Ian: I couldn't make it works when importing the src/styles/theme/palette.ts file,
      // because tailwincss supports only commonJS modules, and the palette.ts file is an ES module.
      // please see if you can solve it
      colors: {
        'green-100': '#daf4de',
        'green-300': '#acebc2',
        'green-500': '#16c172',
        'green-700': '#0a8a69',
        'green-900': '#075a54',
        'peach-100': '#feebda',
        'peach-300': '#fbb291',
        'peach-500': '#f06449',
        'peach-700': '#ac2424',
        'peach-900': '#730e1c',
        'yellow-100': '#fffbcc',
        'yellow-300': '#ffed66',
        'yellow-500': '#ffde00',
        'yellow-700': '#bea917',
        'yellow-900': '#75680d',
        'purple-100': '#f8e9ff',
        'purple-300': '#e5bcff',
        'purple-500': '#c890ff',
        'purple-700': '#7548b8',
        'purple-900': '#381b7a',
        'aqua-100': '#ccf0ed',
        'aqua-300': '#8adce2',
        'aqua-500': '#42badb',
        'aqua-700': '#21709d',
        'aqua-900': '#0c3a6a',
        'coral-100': '#feece2',
        'coral-300': '#fcb9a8',
        'coral-500': '#f8736e',
        'coral-700': '#b23847',
        'coral-900': '#771532',
        'primary-100': '#e2f2ff',
        'primary-200': '#c6e6ff',
        'primary-300': '#abd6ff',
        'primary-400': '#94c7fe',
        'primary-500': '#72afff',
        'primary-600': '#5488db',
        'primary-700': '#3a64b8',
        'primary-800': '#244594',
        'primary-900': '#152f79',
        'grayscale-50': '#F4F5F5',
        'grayscale-000': '#fff',
        'grayscale-100': '#eeefef',
        'grayscale-200': '#dedfdf',
        'grayscale-300': '#bdc0c0',
        'grayscale-400': '#9da1a1',
        'grayscale-500': '#7c8282',
        'grayscale-600': '#5c6363',
        'grayscale-700': '#464d4d',
        'grayscale-800': '#383b3d',
        'grayscale-900': '#2a2e2e',
        'grayscale-1000': '#151717',
        'grayscale-1100': '#0e1010',
        'background-light': '#f6f8fc',
        'background-dark': '#151717',
      },
      height: {
        'fit-content': 'fit-content',
      },
      cursor: {
        grab: 'grab',
      },
      animation: {
        'reverse-spin': 'reverse-spin 1s linear infinite',
        ...generateAnimations('fade-in'),
        ...generateAnimations('fade-in-up'),
        ...generateAnimations('x-scroll'),
        ...generateAnimations('x-scroll-reverse'),
      },
      keyframes: {
        'reverse-spin': {
          from: {
            transform: 'rotate(360deg)',
          },
        },
        'fade-in': {
          '0%': { opacity: 0 },
          '100%': { opacity: 1 },
        },
        'fade-in-up': {
          '0%': { opacity: '0', transform: 'translate3d(0, 100%, 0)' },
          '100%': { opacity: '1', transform: 'none' },
        },
        'fade-in-quick': {
          '0%': { opacity: 0 },
          '33%': { opacity: 1 },
          '66%': { opacity: 1 },
          '100%': { opacity: 1 },
        },
        'x-scroll': {
          from: { transform: 'translateX(0)' },
          to: { transform: 'translateX(-100%) translateX(-1rem)' },
        },
        'x-scroll-reverse': {
          from: { transform: 'translateX(-100%) translateX(-1rem)' },
          to: { transform: 'translateX(0)' },
        },
      },
      backgroundImage: {
        'registration-page':
          'url(https://public.wordup.com.tw/shop/assets/sign+up+page/sign_up.png)',
        'password-page': 'url(https://public.wordup.com.tw/web-assets/forget-password/bg-tile.png)',
      },
      backgroundColor: {
        'rgb-gray': 'rgb(247, 248, 250)',
        'rgb-black': 'rgb(56, 59, 61)',
      },
      fontFamily: {
        noto: [`var(--font--noto-sans-tc)`, `var(--font--noto-sans)`, 'sans-serif'],
      },
    },
  },
  plugins: [
    plugin(({ addUtilities, addBase }) => {
      addUtilities({
        '.fade-right': {
          '-webkit-mask-image': 'linear-gradient(90deg, #000 90%, transparent)',
        },
        '.bottom-left-border': {
          borderLeft: '1px solid #d8d8d8',
          borderImage: 'linear-gradient(to top, #d8d8d8 50%, transparent 50%) 0% 1;',
        },
        '.top-left-border': {
          borderLeft: '1px solid #d8d8d8',
          borderImage: 'linear-gradient(to bottom, #d8d8d8 50%, transparent 50%) 0% 1;',
        },
        '.left-border': {
          borderLeft: '1px solid #d8d8d8',
        },
        '.hide-scrollbar': {
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        },
        '.break-word': {
          wordBreak: 'break-word',
        },
        ...generateClampLineStyles(5),
        '.hyphens-auto': {
          hyphens: 'auto',
        },
        '.shadow-custom': {
          'box-shadow': '0 0 10px 0 rgba(0,0,0,0.1)',
        },
        '.hide-dropdown-arrow': {
          '&::-ms-expand': {
            display: 'none',
          },
          '-webkit-appearance': 'none',
          '-moz-appearance': 'none',
        },
        '.no-text-decoration': {
          'text-decoration': 'none',
        },
        '.list-initial': {
          'list-style-type': 'initial',
        },
        '.custom-icon': {
          fontFamily: 'custom-icon !important',
          fontStyle: 'normal',
        },
        '.animation-paused': {
          animationPlayState: 'paused',
        },
        '.animation-running': {
          animationPlayState: 'running',
        },
        '.animation-reverse': {
          animationDirection: 'reverse',
        },
      })
      addBase({
        '.scrollbar::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
        '.scrollbar::-webkit-scrollbar': {
          height: '8px',
          width: '8px',
        },
        '.scrollbar::-webkit-scrollbar-thumb': {
          backgroundColor: '#eeefef',
          borderRadius: '16px',
          transition: 'background-color 5s ease-in-out',
        },
        '.scrollbar::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#dedfdf',
        },
        '.scrollbar::-webkit-scrollbar-track-piece': {
          background: 'transparent',
        },
      })
    }),
  ],
}

function generateClampLineStyles(lines) {
  const styles = {}

  for (let i = 1; i <= lines; i++) {
    styles[`.clamp-line-${i}`] = {
      display: '-webkit-box',
      '-webkit-line-clamp': `${i}`,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    }
  }

  return styles
}

function generateAnimations(animationName) {
  return {
    [`${animationName}-150`]: `${animationName} 0.15s`,
    [`${animationName}-300`]: `${animationName} 0.3s`,
    [`${animationName}`]: `${animationName} 0.5s`,
    [`${animationName}-700`]: `${animationName} 0.7s`,
    [`${animationName}-1000`]: `${animationName} 1s`,
  }
}
