import Script from 'next/script'
import { pick, omitBy, isNil, isEmpty } from 'lodash-es'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import cookie from '@/libs/cookie'
import { utils as authUtils } from '@/features/auth'
import { GOOGLE_CONVERSION_API } from '@/features/tracking/gtag'
import refreshGtagUserData from '@/features/tracking/gtag/refreshGtagUserData'
import { isProd } from '@/libs/env'

let isGtagInited = false

const InitGtag = () => {
  const router = useRouter()

  useEffect(() => {
    if (!isGtagInited) {
      window.gtag('config', process.env.NEXT_PUBLIC_GTAG_ID, {
        send_page_view: false,
        user_id: authUtils.getUserId(),
        allow_enhanced_conversions: true,
        debug_mode: true,
      })
      refreshGtagUserData()
    }
  }, [])

  useEffect(() => {
    if (router.isReady) {
      const conversionQueries = omitBy(pick(router.query, GOOGLE_CONVERSION_API.VALUES), isNil)
      if (isEmpty(conversionQueries)) return

      const currentTimestamp = new Date().getTime()
      const threeMonthMs = 3 * 30 * 24 * 60 * 60 * 1000
      cookie.set({
        key: GOOGLE_CONVERSION_API.KEY,
        value: JSON.stringify(conversionQueries),
        expires: new Date(currentTimestamp + threeMonthMs),
        domain: process.env.NEXT_PUBLIC_WU_SUB_DOMAIN as string,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady])

  return (
    <>
      <Script
        id='download-gtag'
        strategy='afterInteractive'
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTAG_ID}`}
      />
      <Script
        id='init-gtag'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          `,
        }}
      />
    </>
  )
}

export default InitGtag
