export const getProgressPercentage = ({
  currentBuyerCount,
  goalBuyerCount,
}: {
  currentBuyerCount: number
  goalBuyerCount: number
}) => {
  if (!currentBuyerCount || !goalBuyerCount) return 0

  return Math.floor((currentBuyerCount / goalBuyerCount) * 100) || 0
}
